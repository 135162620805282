<template>
  <section
    id="contacts"
    :style="{ backgroundImage: `url(${imgBg})` }"
    class="section_container section_bg"
  >
    <v-container class="mb-5">
      <v-row justify="space-around" align="center">
        <v-col cols="auto">
          <span class="text-h3 font-weight-medium">{{ title }}</span></v-col
        >
        <v-spacer></v-spacer>
        <v-col cols="auto" class="ml-5">
          <!-- <v-btn color="primary">Напишите нам</v-btn> -->
        </v-col>
        <v-col cols="auto">
          <!-- <v-btn color="primary">Рекламодателям</v-btn> -->
        </v-col>
      </v-row>
      <hr />
      <v-row justify="space-around">
        <!-- по просьбе Ернара - 26.05.2021 -->
        <!-- <v-col cols="12" class="d-flex col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <v-card :color="cardColor" elevation="24" class="flex-grow-1">
            <v-card-text>
              <p class="pl-5" style="color: white">О нас</p>
              {{ textAbout }}
            </v-card-text>
          </v-card>
        </v-col> -->
        <!-- <v-col cols="12" class="d-flex col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <v-card :color="cardColor" elevation="24" class="flex-grow-1">
            <v-card-text>
              <p class="pl-5" style="color: white">Информация</p>
            </v-card-text>
          </v-card>
        </v-col> -->
          <v-card :color="cardColor" elevation="24" class="flex-grow-1">
              <v-card-text>
                  <p class="pl-5" style="color: white">Контактная информация</p>
                  <p>
                      <v-icon>mdi-map-marker-outline</v-icon>
                      Адрес: Астана, улица Кабанбай батыра 19А, Блок А, 9 этаж.
                  </p>
                  <p>
                      <v-icon>mdi-phone-outline</v-icon> Телефон: 8 (7172) 57-18-32
                  </p>
                  <p>
                      <v-icon>mdi-phone-outline</v-icon> Факс: 8 (7172) 24-87-78
                  </p>
                  <!-- <p>
      По вопросам личного кабинета и договора: +7(7172) 70-16-65
      (6254)
    </p> -->
                  <p>
                      <v-icon>mdi-email-open-outline</v-icon> Эл. почта:
                      isung@iacng.kz
                  </p>
              </v-card-text>
          </v-card>
        <links class="mr-5" />
        <!-- <v-col cols="12" class="d-flex col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <v-card
            id="contacts"
            :color="cardColor"
            elevation="24"
            class="flex-grow-1"
          >
            <v-card-text>
              <p class="pl-5" style="color: white">Контакты</p>
              <table
                class="golovnoi__ofis"
                style="width: 100%; margin-left: auto; margin-right: auto"
                border="1"
              >
                <tbody>
                  <tr>
                    <td style="width: 4.983%">
                      <p><strong>№</strong></p>
                    </td>
                    <td style="width: 56.017%">
                      <p><strong>Головной офис</strong></p>
                    </td>
                    <td style="width: 38%">
                      <p><strong>Телефоны</strong></p>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 4.983%">
                      <p>1</p>
                    </td>
                    <td style="width: 56.017%">
                      <p>Контакт-центр проекта «ИСУН»</p>
                    </td>
                    <td style="width: 38%">
                      <p>+7 (7172) 701&nbsp;999, 702 000</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-card-text>
          </v-card>
        </v-col> -->
        <!-- <v-col cols="12"><public-links /></v-col> -->
      </v-row>
    </v-container>
  </section>
</template>
<style lang="scss" scoped>
.section_container {
  width: 100%;
  margin: 0 auto;
  background-color: black;
}
.section_link {
  color: white;
  text-decoration: none;
}
.section_link:hover {
  color: black;
}
.section_bg {
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
<script>
import { get, sync } from "vuex-pathify";
// import Links from "@/components/Links";
export default {
  name: "HomeSectionAnalytics",
  computed: {
    ...get("app", ["title"])
  },

  components: {
    PublicLinks: () => import("@/components/Links")
  },
  data() {
    return {
      imgBg: require("@/assets/img/footer-bg.jpg"),
      cardColor: "#1000",
      textAbout: `ИСУНГ предназначена для комплексного информационно-аналитического обеспечения процесса учета производства и оборота сырой нефти и газового конденсата.
Задачами создания ИСУНГ являются:
- осуществление автоматического сбора данных о производстве и обороте сырой нефти и газового конденсата посредством приборов учета установленных у субъектов осуществляющих деятельность в сфере добычи и оборота нефти;
- осуществление оператором ИК-услуги приема и обработки данных о производстве и обороте нефти в режиме реального времени с дальнейшей передачей в уполномоченный орган в области углеводородов;
- осуществление уполномоченным органом в области углеводородов а и анализа данных, получаемых с приборов учета в режиме реального времени.`
    };
  }
};
</script>
